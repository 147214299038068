// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Platform_Feat_slider from "../components/common/platform-feat-slider"
import Construction from "../images/advanced-materials/icons/Construction.svg"
import Fit from "../images/advanced-materials/icons/fit.svg"
import Flexible from "../images/advanced-materials/icons/Flexible.svg"
import Constructionwhite from "../images/advanced-materials/icons/Constructionwhite.svg"
import Fitwhite from "../images/advanced-materials/icons/fitwhite.svg"
import Flexiblewhite from "../images/advanced-materials/icons/Flexiblewhite.svg"

const AdvancedMaterials = ({ data }) => {
  const images = {
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    grid_4: convertToBgImage(getImage(data.grid_4)),
    grid_5: convertToBgImage(getImage(data.grid_5)),
    grid_6: convertToBgImage(getImage(data.grid_6)),
    mint_texture: convertToBgImage(getImage(data.mint_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),
    platform_application_1: convertToBgImage(
      getImage(data.platform_application_1)
    ),
    platform_application_2: convertToBgImage(
      getImage(data.platform_application_2)
    ),
  }

  const [activePlatform, setActivePlatform] = useState(0)

  const platform_feat_slider_array = [
    {
      title: "Construction",
      desc: "Expert craftsmanship to enable wash, stretch, durability and comfort in creating complex solutions.",
      icon: Construction,
    },

    {
      title: "Flexibility",
      desc: "Design freedom with form factor optionality ranging from 3D structures to no-seam attachments.",
      icon: Flexible,
    },

    {
      title: "Fit",
      desc: "Achieving customizable t, from desired sizing for apparel, to engineering our textiles to wrap around hard structures for consumer electronics.",
      icon: Fit,
    },
  ]

  const slider = [
    {
      title: "Proprietary ideation and development",
      content:
        "Our unique integrated approach to design and functionality reflects a commitment to sustainability and engineered craftsmanship.",
      image: images.slider_1,
    },
    {
      title: "Integrating technology",
      content:
        "We are pioneers in engineering soft goods with integrated structure and embedded electronic components.",
      image: images.slider_2,
    },
    {
      title: "Industry standards",
      content:
        "We operate ISO 9001, 14001 and 13485, OHSAS 18001 and LEED-compliant manufacturing facilities globally.",
      image: images.slider_3,
    },
    {
      title: "Manufacturing",
      content:
        "Our innovative manufacturing solutions merge craftsmanship with the replicability and scale required for consumer electronics.",
      image: images.slider_1,
    },
  ]

  const [active, setActive] = useState(0)

  const platform = [
    {
      title: "Connected Clothing",
      sub_title: "Reactive Bra - Sustainability Edit",
      content:
        "Designed to converge sustainability into a wearable using seamless tech integration - resulting in a product where fitness and sustainability intersect.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.platform_application_1,
    },
    {
      title: "Devices",
      sub_title: "Speakers",
      content:
        "Purpose built audio fabrics that can be paired with smart textiles to enhance functionality from touch control micro-LED indications to tactile feedback.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.platform_application_2,
    },
  ]

  return (
    <Layout>
      <Seo title="Advanced Materials" />
      <section>
        <BackgroundImage
          {...images.salmon_texture}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full z-10 sm:mt-0 -mt-52 ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 max-w-2xl text-white">
                    Elevating
                    <div>Functionality</div>
                  </div>
                  <div className="sm:text-xl text-xl sm:max-w-lg text-white font-light mt-2  sm:text-left text-center">
                    Solutions to the industry’s growing
                    <div>user experience needs</div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className=" absolute right-0 bottom-0">
                  <StaticImage
                    src="../images/advanced-materials/hero.png"
                    alt="Softmatter"
                    placeholder="blurred"
                    width={750}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-salmon mb-4 uppercase text-center ">
                    BREAKTHROUGH IN FUNCTIONALITY
                  </div>
                  <div className=" text-2xl sm:text-4xl text-center font-light mb-4 text-dusk">
                    Unrestricted Design Flexibility
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. The platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className=" text-xl sm:text-xl font-light text-center max-w-5xl leading-snug mt-4 text-dusk">
                    Our innovations in materials engineering offer a range of
                    novel capabilities allowing for a new generation of devices
                    that are soft, stretchable, and anatomically compliant –
                    enabling efficient bio-integration and withstanding of high
                    tensile stress associated with on-body applications.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <div className="grid md:grid-cols-3 lg:grid-cols-5 grid-cols-1 gap-4 mt-8 text-dusk">
                    <div className="border lg:col-start-2 hover:bg-salmon border-salmon p-5 p sm:text-left text-center b-10 group duration-200">
                      <div className="flex justify-center sm:justify-start">
                        <img
                          src={Construction}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer group-hover:hidden block my-3"
                        />
                        <img
                          src={Constructionwhite}
                          width={80}
                          alt="Softmatter"
                          className="my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b group-hover:border-white group-hover:text-white border-salmon text-dusk group duration-200 pb-4 max-w-5xl leading-snug mt-16">
                        Construction
                      </div>
                      <div className="group-hover:text-white text-sm font-light max-w-5xl leading-snug mt-4">
                        Expert craftsmanship to enable wash, stretch, durability
                        and comfort in creating complex solutions.
                      </div>
                    </div>
                    <div className="border hover:bg-salmon group border-salmon sm:text-left text-center  p-5 pb-10 group duration-200">
                      <div className="flex justify-center sm:justify-start">
                        <img
                          src={Flexible}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer group-hover:hidden block my-3"
                        />
                        <img
                          src={Flexiblewhite}
                          width={80}
                          alt="Softmatter"
                          className="my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b group-hover:border-white group-hover:text-white border-salmon text-dusk group duration-200 pb-4 max-w-5xl leading-snug mt-16">
                        Flexibility
                      </div>
                      <div className="group-hover:text-white text-sm font-light max-w-5xl leading-snug mt-4">
                        Design freedom with form factor optionality ranging from
                        3D structures to no-seam attachments.
                      </div>
                    </div>
                    <div className="border hover:bg-salmon group border-salmon sm:text-left text-center  p-5 pb-10 group duration-200">
                      <div className="flex justify-center sm:justify-start">
                        <img
                          src={Fit}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer group-hover:hidden block my-3"
                        />
                        <img
                          src={Fitwhite}
                          width={80}
                          alt="Softmatter"
                          className="my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b group-hover:border-white group-hover:text-white border-salmon text-dusk group duration-200 pb-4 max-w-5xl leading-snug mt-16">
                        Fit
                      </div>
                      <div className="group-hover:text-white text-sm font-light max-w-5xl leading-snug mt-4">
                        Achieving customizable fit, from desired sizing for
                        apparel, to engi- neering our textiles to wrap around
                        hard structures for consumer elec- tronics.
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk">
          <div className=" py-24">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin  mb-4">
                  PLATFORMS
                </div>
                <div className="text-center text-6xl mb-4">
                  Advanced Material
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  A collection of complex and recycled materials and finishes
                </div>
              </div>
            </Fade>
            {/* start */}

            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col">
                <div className="lg:hidden block w-full">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_1}
                        className="sm:py-60 py-44"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Bio-mimicking material
                      </div>
                      <div className="text-base max-w-xl">
                        Thermo-responsive polymers and yarns, pine-cone
                        biomimetic yarn for moisture management for improved
                        comfort over extended wear times
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.grid_1}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>

              {/* end */}
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_2}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Biomaterials and
                        <div>Recycled Yarn</div>
                      </div>
                      <div className="lg:text-base max-w-xl">
                        We source materials sustainably and partner with supply
                        chain suppliers to develop raw materials that are
                        organic, recycled, natural, and/or responsibly sourced
                      </div>

                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col">
                <Fade right>
                  <div className="lg:hidden block w-full">
                    <BackgroundImage
                      {...images.grid_3}
                      className="sm:py-60 py-44 bg-gray-400"
                    ></BackgroundImage>
                  </div>
                </Fade>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className=" text-4xl font-light mb-3 leading-tight">
                        PU foam-based
                        <div>Injection Molding</div>
                      </div>
                      <div className=" text-base md:text-base max-w-xl">
                        Replacing hard plastic components for flexibility,
                        <div>
                          durability, and seamless integration of sensors
                        </div>
                      </div>

                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="hidden lg:block  lg:w-1/2 w-full">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_3}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full">
                  <Fade left>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.grid_4}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Acoustic Dampening Fabrics
                      </div>
                      <div className="text-base max-w-xl">
                        Certified Acoustic fabrics specifically designed for
                        uninterrupted sound passthrough from materials to
                        product
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col">
                <div className="lg:hidden block w-full">
                  <BackgroundImage
                    {...images.grid_5}
                    className="sm:py-60 py-44 bg-gray-400"
                  ></BackgroundImage>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Memory Foam with
                        <div>PU Leather</div>
                      </div>
                      <div className="text-base max-w-xl">
                        Internal and external fabric/leather lamination on foam
                        provides superior comfort and flexibility. Memory foam
                        built for high impact/low frequency
                      </div>

                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="hidden lg:w-1/2 w-full lg:block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_5}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className=" flex lg:flex-row flex-col">
                <div className="lg:w-1/2 w-full">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.grid_6}
                        className="py-60 bg-gray-400"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:py-0 py-20 lg:w-1/2 w-full px-10 sm:px-20 lg:px-28 xl:px-36 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white lg:pr-10 sm:text-left text-center">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Silicone Printing and Embellishments
                      </div>
                      <div className="text-base max-w-xl">
                        Patented Silicone Application Technology to enable
                        targeted modification of fabric modulus using engineered
                        functional prints for grip and comfort
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 text-xl border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          REQUEST INFORMATION
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Application
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                “Integration of technology into textiles to create robust,
                scalable,
                <div>and functional solutions.”</div>
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-32 sm:gap-5">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            active == index
                              ? "font-firs-semibold cursor-pointer text-dusk text-xl pb-2 border-b-2 px-5 border-dusk duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk text-xl pb-2 border-b-2 px-5 border-transparent duration-200"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[active].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[active].sub_title}
                      </div>
                      <div className="text-xl font-light mt-4 text-white max-w-2xl">
                        {platform[active].content}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content2}
                      </div>
                      <div className="text-xl font-light text-white max-w-2xl">
                        {platform[active].content3}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="lg:w-1/2 w-full">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="lg:w-1/2 w-full">
            <Fade bottom cascade>
              <div className="bg-chalk py-56 text-dusk px-5">
                <div className="text-center text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  industry-leading expertise in smart textiles, from design to
                  manufacture. We help you strategically enhance your offering
                  so that it has a profound, global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-salmon  hover:bg-salmon/90 duration-200 text-dusk text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AdvancedMaterialsImages {
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_1: file(relativePath: { eq: "advanced-materials/grid_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_2: file(relativePath: { eq: "advanced-materials/grid_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_3: file(relativePath: { eq: "advanced-materials/grid_3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_4: file(relativePath: { eq: "advanced-materials/grid_4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_5: file(relativePath: { eq: "advanced-materials/grid_5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    grid_6: file(relativePath: { eq: "advanced-materials/grid_6.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    platform_application_1: file(
      relativePath: { eq: "advanced-materials/Reactivebra.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    platform_application_2: file(
      relativePath: { eq: "advanced-materials/Device.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    mint_texture: file(relativePath: { eq: "home/mint_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default AdvancedMaterials
